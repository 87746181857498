import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import iconSenha from '../../../resources/img/icon-senha.svg';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import Paginacao from '../../../Components/Paginacao';
import Modal from '../../../Components/Modal';
import { dataBrMask } from '../../../Utils/mask';

export default function CardStatementDetails(props) {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [transacao, setTransacao] = useState({});
    const [senha, setSenha] = useState('');

    const user = JSON.parse(sessionStorage.getItem("userData"));

    const status_transacao = ["Não Autorizada", "Aguardando pagamento", "Em análise", "Aprovada", "Disponível", "Em disputa", "Devolvida", "Cancelada"];
    const classe_transacao = ["red", "grey", "grey", "green", "green", "grey", "grey", "grey"];

    useEffect(() => {
        const id = props.location.state.id;
        carregaTransacao(id);
    }, []);

    const carregaTransacao = (id) => {
        setLoading(true);
        var url = 'transacao/transacao/' + id;
        ApiService.get(url, user.token)
            .then(result => {
                const response = result.data;
                const dados = [];
                var data = response.data.datahora.split(" ");
                var hora = data[1].split(":");
                setTransacao({
                    data: Funcoes.data2Brazilian(data[0]) + ' ' + hora[0] + ':' + hora[1],
                    paymentid: response.data.paymentid,
                    status: status_transacao[response.data.status],
                    status_id: response.data.status,
                    classe: classe_transacao[response.data.status],
                    final_cartao: "**** "+response.data.finalcartao,
                    parcelamento: response.data.parcelas + "x",
                    valorbruto: "R$ " + Funcoes.float2Preco(response.data.valor),
                    taxas: "R$ " + Funcoes.float2Preco(response.data.taxas),
                    liquido: "R$ " + Funcoes.float2Preco(response.data.liquido),
                    liberacao: Funcoes.data2Brazilian(response.data.liberacao)
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const cancelar = () => {
        if(senha === ''){
            setAlertType(2);
            setAlertText("Preencha o campo senha corretamente");
            setAlert(true);
        }else{
            console.log(user.token);
            setLoading(true);
            ApiService.post('transacao/cancelar', {payment_id: transacao.paymentid, senha: senha}, user.token)
                .then(result => {
                    const response = result.data;
                    setLoading(false);
                    setAlertType(1); 
                    setAlertText('Transação cancelada com sucesso.'); 
                    setModal(false); 
                    setAlert(true);
                })
                .catch(err => {
                    console.log(err);
                    const result = ApiService.handleErros(err.response);
                    if (result.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        setPath('/');
                    }
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
        }
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false) }} />
            <Modal show={modal} largura={380}>
                <div className="modalConfirm">
                    <h3>Atenção</h3>
                    <img src={iconSenha} alt="Icone de senha" />
                    <p>Digite sua senha para confirmar a operação.</p>
                    <Input type="password" value={senha} onChange={e => { setSenha(e.target.value) }} />
                    <button className="btn btnConfirm" onClick={() => { cancelar() }}>Confirmar</button>
                    <button className="btn btnCancel" onClick={() => { setModal(false); }}>cancelar</button>
                </div>
            </Modal>
            <SideMenu menuAtivo={4} />

            <div className="body">
                <Header />

                <div className="cardStatement mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Detalhes da transação</h1>
                            <p className="descricao">Informamos abaixo os dados da transação selecionada.</p>
                        </div>
                        {transacao.status_id === 3 || transacao.status_id === 4 ? 
                            (<>
                                <button className="btn" onClick={() => setModal(true)}>Cancelar Transação</button>
                            </>)
                        : ''}
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Informações</h3>
                        </div>
                        <div className="panelBody">
                            <div className="rowCenter">
                                <div className="info">
                                    <p>Data da Transação</p>
                                    <h3>{transacao.data}</h3>
                                </div>
                                <div className="info">
                                    <p>Código da Transação</p>
                                    <h3>{transacao.paymentid}</h3>
                                </div>
                                <div className="info">
                                    <p>Status</p>
                                    <h3 className={transacao.classe}>{transacao.status}</h3>
                                </div>
                            </div>
                            <div className="rowCenter">
                                <div className="info">
                                    <p>Final do Cartão</p>
                                    <h3>{transacao.final_cartao}</h3>
                                </div>
                                <div className="info">
                                    <p>Parcelamento</p>
                                    <h3>{transacao.parcelamento}</h3>
                                </div>
                                <div className="info">
                                    <p>Valor Bruto</p>
                                    <h3>{transacao.valorbruto}</h3>
                                </div>
                                <div className="info">
                                    <p>Taxas</p>
                                    <h3 className="red">{transacao.taxas}</h3>
                                </div>
                                <div className="info">
                                    <p>Total Liquído</p>
                                    <h3>{transacao.liquido}</h3>
                                </div>
                                <div className="info">
                                    <p>Liberação do Valor</p>
                                    <h3>{transacao.liberacao}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Link to="/transacoes" className="btn btnVoltar">{'<'} Voltar</Link>
                </div>
            </div>
        </>
    )
}
