import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css';

import logoExossWallet from '../resources/img/logo-exosswallet-preto.png';
import iconDashboard from '../resources/img/menu-dashboard.svg';
import iconAdquirencia from '../resources/img/menu-adquirencia.svg';
import iconPaymentLinks from '../resources/img/menu-payment-links.svg';
import iconAssinaturas from '../resources/img/menu-assinaturas.svg';
import iconMeusDados from '../resources/img/menu-meus-dados.svg';
import iconCobrancas from '../resources/img/menu-cobrancas.svg';

export default function SideMenu({ menuAtivo }) {
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [user, setUser] = useState({ data: { produtos: [] } });
    const [menuOpen, setMenuOpen] = useState(false);
    const [menus] = useState([
        { nome: 'Dashboard', url: '/home', icone: iconDashboard },
        { nome: 'Cobranças', url: '/cobrancas', icone: iconCobrancas },
        { nome: 'Links de Pagamento', url: '/payment-links', icone: iconPaymentLinks },
        { nome: 'Transações', url: '/transacoes', icone: iconAdquirencia },
        //{ nome: 'Assinaturas', url: '/assinaturas', icone: iconAssinaturas },
    ]);

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            setUser(user);
            const nome = user.vendedor.apelidofantasia.split(" ");
            setNomeUsuario(nome[0]);
        } else {
            setNomeUsuario('Usuário');
        }
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <button className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </button>

            <div className={`menu-overlay ${menuOpen ? 'active' : ''}`} onClick={closeMenu}></div>

            <div className={`sideMenuBar ${menuOpen ? 'active' : ''}`}>
                <div className="profile">
                    <img src={logoExossWallet} alt="Exoss" />
                    <p>Olá {nomeUsuario}.</p>
                </div>
                <div className="navigation">
                    <ul>
                        {menus.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link 
                                        to={item.url} 
                                        className={menuAtivo === (i + 1) ? 'active' : ''}
                                        onClick={closeMenu}
                                    >
                                        <img src={item.icone} width="18" alt={`Ícone de ${item.nome}`} /> {item.nome}
                                    </Link>
                                </li>
                            )
                        })}
                        <li>
                            <Link to="/meus-dados/minha-conta" className={`nav-link collapsed`} data-toggle="collapse" data-target="#collapseMeusDados"
                                aria-expanded="true" aria-controls="collapseMeusDados">
                                <img src={iconMeusDados} alt="Meus Dados" />
                                <span>Meus Dados</span>
                            </Link>
                            <div id="collapseMeusDados" className="collapse" aria-labelledby="headingMeusDados"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item submenu" to="/meus-dados/minha-conta" onClick={closeMenu}>Minha Conta</Link>
                                    <Link className="collapse-item submenu" to="/meus-dados/configuracoes" onClick={closeMenu}>Configurações</Link>
                                    <Link className="collapse-item submenu" to="/meus-dados/taxas" onClick={closeMenu}>Taxas</Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}