import React, { useState, useEffect } from 'react';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import { validateCPF, validateEmail, formatCPF, formatPhone } from '../../../Utils/validations';
import NoResults from '../../../Components/NoResults';
import Pagination from '../../../Components/Pagination';

import iconLixeira from '../../../resources/img/icon-lixeira.svg';
import iconVisualizar from '../../../resources/img/icon-visualizar.svg';
import iconCopy from '../../../resources/img/icon-copy.svg';

export default function PaymentLinks() {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [links, setLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');
    const [editingLink, setEditingLink] = useState(null);
    const [tipoprodserv, setTipoProdServ] = useState(1);
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState(0);
    const [maxparcsemjuros, setMaxParcSemJuros] = useState(12);
    const [datavalidade, setDataValidade] = useState('');
    const [clinome, setCliNome] = useState('');
    const [clidocumento, setCliDocumento] = useState('');
    const [cliemail, setCliEmail] = useState('');
    const [clicelular, setCliCelular] = useState('');

    useEffect(() => {
        loadLinks(1);
    }, []);

    const loadLinks = (page) => {
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        
        ApiService.get(`payment-links?page=${page}`, userData.token)
            .then(result => {
                setLinks(result.data.data);
                setTotalPages(result.data.last_page);
                setCurrentPage(result.data.current_page);
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const handleCreate = () => {
        setEditingLink(null);
        clearForm();
        setShowModal(true);
    };

    const handleEdit = (link) => {
        setEditingLink(link);
        setTipoProdServ(link.lnk_tipoprodserv);
        setDescricao(link.lnk_descricao);
        setValor(link.lnk_valor);
        setMaxParcSemJuros(link.lnk_maxparcsemjuros);
        setDataValidade(link.lnk_datavalidade.split(' ')[0]);
        setCliNome(link.lnk_clinome);
        setCliDocumento(link.lnk_clidocumento);
        setCliEmail(link.lnk_cliemail);
        setCliCelular(link.lnk_clicelular);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Tem certeza que deseja excluir este link de pagamento?')) {
            setLoading(true);
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            
            ApiService.customRequest(`payment-links/${id}`, {}, 'DELETE', userData.token)
                .then(() => {
                    setAlertType(1);
                    setAlertText('Link de pagamento excluído com sucesso!');
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                    loadLinks(currentPage);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                });
        }
    };

    const clearForm = () => {
        setTipoProdServ(1);
        setDescricao('');
        setValor(0);
        setMaxParcSemJuros(12);
        setDataValidade('');
        setCliNome('');
        setCliDocumento('');
        setCliEmail('');
        setCliCelular('');
    };

    const handleSubmit = () => {
        // Validar campos obrigatórios
        if (!tipoprodserv || !descricao || !valor || !datavalidade || !maxparcsemjuros) {
            setAlertType(2);
            setAlertText('Preencha todos os campos obrigatórios!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        // Validar CPF se preenchido
        if (clidocumento && !validateCPF(clidocumento)) {
            setAlertType(2);
            setAlertText('CPF inválido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        // Validar email se preenchido
        if (cliemail && !validateEmail(cliemail)) {
            setAlertType(2);
            setAlertText('E-mail inválido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const data = {
            tipoprodserv,
            descricao,
            valor: parseFloat(valor),
            maxparcsemjuros,
            datavalidade,
            clinome,
            clidocumento: clidocumento.replace(/\D/g, ''), // Remove máscara antes de enviar
            cliemail,
            clicelular: clicelular.replace(/\D/g, '') // Remove máscara antes de enviar
        };

        const method = editingLink ? 'PUT' : 'POST';
        const url = editingLink ? `payment-links/${editingLink.lnk_id}` : 'payment-links';

        ApiService.customRequest(url, data, method, userData.token)
            .then((response) => {
                console.log('API Response:', response.data);
                
                // Primeiro fecha o modal de edição
                setShowModal(false);
                
                // Verifica se o link está em diferentes lugares possíveis na resposta
                const link = response.data?.link || response.data?.data?.link || response.data?.lnk_link;
                
                if (link) {
                    console.log('Setting payment link:', link);
                    setPaymentLink(link);
                    // Mostra o modal do link depois de um pequeno delay
                    setTimeout(() => {
                        setShowLinkModal(true);
                    }, 500);
                } else {
                    console.log('No link found in response');
                }
                
                // Mostra a mensagem de sucesso
                setAlertType(1);
                setAlertText(`Link de pagamento ${editingLink ? 'atualizado' : 'criado'} com sucesso!`);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
                
                loadLinks(currentPage);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            
            <Modal show={showLinkModal} largura={390}>
                <div className="modalConfirm" style={{ textAlign: 'center' }}>
                    <h3>Link de Pagamento</h3>
                    <div style={{ 
                        background: '#f5f5f5', 
                        padding: '15px', 
                        borderRadius: '4px', 
                        margin: '20px 0',
                        wordBreak: 'break-all',
                        fontSize: '14px'
                    }}>
                        {paymentLink}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <button 
                            className="btn btnConfirm"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                navigator.clipboard.writeText(paymentLink);
                                const btn = document.createElement('div');
                                btn.innerHTML = 'Link copiado!';
                                btn.style.position = 'fixed';
                                btn.style.top = '20px';
                                btn.style.right = '20px';
                                btn.style.background = '#28a745';
                                btn.style.color = 'white';
                                btn.style.padding = '10px';
                                btn.style.borderRadius = '4px';
                                btn.style.zIndex = '9999';
                                document.body.appendChild(btn);
                                setTimeout(() => document.body.removeChild(btn), 2000);
                                setShowLinkModal(false);
                            }}
                        >
                            Copiar Link
                        </button>
                        <button className="btnCancelar" onClick={() => setShowLinkModal(false)}>
                            Fechar
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal show={showModal} largura={390}>
                <div className="modalConfirm">
                    <h3>{editingLink ? 'Editar Link de Pagamento' : 'Novo Link de Pagamento'}</h3>
                    <div style={{ textAlign: 'left' }}>
                        <Input 
                            type="select" 
                            label="Tipo *" 
                            value={tipoprodserv}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setTipoProdServ(parseInt(e.target.value))}
                            optionsData={[
                                { value: 1, text: 'Produto' },
                                { value: 2, text: 'Serviço' },
                                { value: 3, text: 'Produto e Serviço' }
                            ]}
                        />
                        
                        <Input
                            type="text"
                            label="Descrição *"
                            value={descricao}
                            maxLength={60}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setDescricao(e.target.value)}
                        />

                        <Input
                            type="money"
                            label="Valor *"
                            value={valor}
                            divStyle={{ marginBottom: 27 }}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                        />

                        <Input
                            type="number"
                            label="Máximo de Parcelas sem Juros *"
                            value={maxparcsemjuros}
                            min={1}
                            max={12}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setMaxParcSemJuros(parseInt(e.target.value))}
                        />

                        <Input
                            type="date"
                            label="Data de Validade *"
                            value={datavalidade}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setDataValidade(e.target.value)}
                        />

                        <Input
                            type="text"
                            label="Nome do Cliente"
                            value={clinome}
                            maxLength={80}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setCliNome(e.target.value)}
                        />

                        <Input
                            type="text"
                            label="CPF"
                            value={clidocumento}
                            maxLength={14}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setCliDocumento(formatCPF(e.target.value))}
                        />

                        <Input
                            type="email"
                            label="E-mail"
                            value={cliemail}
                            maxLength={80}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setCliEmail(e.target.value)}
                        />

                        <Input
                            type="text"
                            label="Celular"
                            value={clicelular}
                            maxLength={15}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setCliCelular(formatPhone(e.target.value))}
                        />
                    </div>
                    <button className="btn btnConfirm" onClick={handleSubmit}>
                        {editingLink ? 'Atualizar' : 'Criar'}
                    </button>
                    <button className="btnCancelar" onClick={() => setShowModal(false)}>
                        cancelar
                    </button>
                </div>
            </Modal>
            <SideMenu menuAtivo={5} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Links de Pagamento</h1>
                        </div>
                        <button className="btn" onClick={handleCreate}>Novo Link</button>
                    </div>

                    <div className="panel">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Cliente</th>
                                    <th>Validade</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {links.length > 0 ? (
                                    links.map(link => (
                                        <tr key={link.lnk_id}>
                                            <td>{link.lnk_descricao}</td>
                                            <td>R$ {Funcoes.float2Preco(link.lnk_valor)}</td>
                                            <td>{link.lnk_clinome}</td>
                                            <td>{Funcoes.geraDataExtenso(link.lnk_datavalidade.split(' ')[0])}</td>
                                            <td>
                                                <button className="btn btnIcon" onClick={() => handleEdit(link)} style={{ marginRight: 10 }}>
                                                    <img src={iconVisualizar} alt="Editar" width="18" />
                                                </button>
                                                {link.link && (
                                                    <button 
                                                        className="btn btnIcon" 
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(link.link);
                                                            const btn = document.createElement('div');
                                                            btn.innerHTML = 'Link copiado!';
                                                            btn.style.position = 'fixed';
                                                            btn.style.top = '20px';
                                                            btn.style.right = '20px';
                                                            btn.style.background = '#28a745';
                                                            btn.style.color = 'white';
                                                            btn.style.padding = '10px';
                                                            btn.style.borderRadius = '4px';
                                                            btn.style.zIndex = '9999';
                                                            document.body.appendChild(btn);
                                                            setTimeout(() => document.body.removeChild(btn), 2000);
                                                        }}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <img src={iconCopy} alt="Copiar link" width="18" />
                                                    </button>
                                                )}
                                                <button className="btn btnIcon" onClick={() => handleDelete(link.lnk_id)}>
                                                    <img src={iconLixeira} alt="Excluir" width="18" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <NoResults />
                                )}
                            </tbody>
                        </table>

                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                loadLinks(page);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
