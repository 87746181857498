import React, { useState, useEffect } from 'react';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import NoResults from '../../../Components/NoResults';
import Pagination from '../../../Components/Pagination';

import iconLixeira from '../../../resources/img/icon-lixeira.svg';
import iconVisualizar from '../../../resources/img/icon-visualizar.svg';
import iconCopy from '../../../resources/img/icon-copy.svg';

export default function Assinaturas() {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [assinaturas, setAssinaturas] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [editingAssinatura, setEditingAssinatura] = useState(null);

    // Form states
    const [nome, setNome] = useState('');
    const [valor, setValor] = useState(0);
    const [softDescription, setSoftDescription] = useState('');
    const [recorrente, setRecorrente] = useState(1);
    const [urlRetorno, setUrlRetorno] = useState('');
    const [urlWebhook, setUrlWebhook] = useState('');
    const [corPrimaria, setCorPrimaria] = useState('#FF5733');
    const [logo, setLogo] = useState('');

    useEffect(() => {
        loadAssinaturas(1);
    }, []);

    const loadAssinaturas = (page) => {
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        
        ApiService.get(`assinaturas?page=${page}`, userData.token)
            .then(result => {
                setAssinaturas(result.data.data);
                setTotalPages(result.data.last_page);
                setCurrentPage(result.data.current_page);
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const handleCreate = () => {
        setEditingAssinatura(null);
        clearForm();
        setShowModal(true);
    };

    const handleEdit = (assinatura) => {
        setEditingAssinatura(assinatura);
        setNome(assinatura.nome);
        setValor(assinatura.valor);
        setSoftDescription(assinatura.soft_description);
        setRecorrente(assinatura.recorrente);
        setUrlRetorno(assinatura.url_retorno);
        setUrlWebhook(assinatura.url_webhook);
        setCorPrimaria(assinatura.cor_primaria);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Tem certeza que deseja excluir esta assinatura?')) {
            setLoading(true);
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            
            ApiService.customRequest(`assinaturas/${id}`, {}, 'DELETE', userData.token)
                .then(() => {
                    setAlertType(1);
                    setAlertText('Assinatura excluída com sucesso!');
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                    loadAssinaturas(currentPage);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                });
        }
    };

    const clearForm = () => {
        setNome('');
        setValor(0);
        setSoftDescription('');
        setRecorrente(1);
        setUrlRetorno('');
        setUrlWebhook('');
        setCorPrimaria('#FF5733');
        setLogo('');
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = () => {
        // Validação dos campos obrigatórios
        if (!nome.trim()) {
            setAlertType(2);
            setAlertText('O nome do plano é obrigatório!');
            setAlert(true);
            return;
        }
        if (!valor || valor <= 0) {
            setAlertType(2);
            setAlertText('O valor é obrigatório e deve ser maior que zero!');
            setAlert(true);
            return;
        }
        if (!softDescription.trim()) {
            setAlertType(2);
            setAlertText('A descrição resumida é obrigatória!');
            setAlert(true);
            return;
        }
        if (!urlRetorno.trim()) {
            setAlertType(2);
            setAlertText('A URL de retorno é obrigatória!');
            setAlert(true);
            return;
        }

        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const data = {
            assvdr_nome: nome,
            assvdr_valor: parseFloat(valor),
            assvdr_softdescription: softDescription,
            assvdr_recorrente: recorrente,
            assvdr_urlretorno: urlRetorno,
            assvdr_urlwebhook: urlWebhook,
            assvdr_corprimaria: corPrimaria,
            logo: logo
        };

        const method = editingAssinatura ? 'PUT' : 'POST';
        const url = editingAssinatura ? `assinaturas/${editingAssinatura.id}` : 'assinaturas';

        ApiService.customRequest(url, data, method, userData.token)
            .then(() => {
                setAlertType(1);
                setAlertText(`Assinatura ${editingAssinatura ? 'atualizada' : 'criada'} com sucesso!`);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
                setShowModal(false);
                loadAssinaturas(currentPage);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            <Modal show={showModal} largura={390}>
                <div className="modalConfirm">
                    <h3>{editingAssinatura ? 'Editar Assinatura' : 'Nova Assinatura'}</h3>
                    <div style={{ textAlign: 'left' }}>
                        <Input
                            type="text"
                            label="Nome do Plano *"
                            value={nome}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setNome(e.target.value)}
                            maxLength={80}
                        />

                        <Input
                            type="money"
                            label="Valor *"
                            value={valor}
                            divStyle={{ marginBottom: 27 }}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                        />

                        <Input
                            type="text"
                            label="Descrição Resumida *"
                            value={softDescription}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setSoftDescription(e.target.value)}
                            maxLength={60}
                        />

                        <Input 
                            type="select" 
                            label="Recorrente *" 
                            value={recorrente}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setRecorrente(parseInt(e.target.value))}
                            optionsData={[
                                { value: 1, text: 'Sim' },
                                { value: 0, text: 'Não' }
                            ]}
                        />

                        <Input
                            type="text"
                            label="URL de Retorno *"
                            value={urlRetorno}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setUrlRetorno(e.target.value)}
                            maxLength={255}
                        />

                        <Input
                            type="text"
                            label="URL de Webhook"
                            value={urlWebhook}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setUrlWebhook(e.target.value)}
                            maxLength={255}
                        />

                        <Input
                            type="color"
                            label="Cor Primária"
                            value={corPrimaria}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setCorPrimaria(e.target.value)}
                        />

                        <div style={{ marginBottom: 27 }}>
                            <label>Logo</label>
                            <div className="upload-field">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    className="form-control"
                                />
                                {logo && (typeof logo === 'string' || logo?.url) && (
                                    <img 
                                        src={typeof logo === 'string' ? logo : logo.url} 
                                        alt="Logo atual" 
                                        className="preview-image"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <button className="btn btnConfirm" onClick={handleSubmit}>
                        {editingAssinatura ? 'Atualizar' : 'Criar'}
                    </button>
                    <button className="btnCancelar" onClick={() => setShowModal(false)}>
                        cancelar
                    </button>
                </div>
            </Modal>
            <SideMenu menuAtivo={4} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Assinaturas</h1>
                        </div>
                        <button className="btn" onClick={handleCreate}>Nova Assinatura</button>
                    </div>

                    <div className="panel">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Valor</th>
                                    <th>Descrição</th>
                                    <th>Recorrente</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assinaturas.length > 0 ? (
                                    assinaturas.map(assinatura => (
                                        <tr key={assinatura.id}>
                                            <td>{assinatura.nome}</td>
                                            <td>R$ {Funcoes.float2Preco(assinatura.valor)}</td>
                                            <td>{assinatura.soft_description}</td>
                                            <td>{assinatura.recorrente ? 'Sim' : 'Não'}</td>
                                            <td>
                                                <button className="btn btnIcon" onClick={() => handleEdit(assinatura)} style={{ marginRight: 10 }}>
                                                    <img src={iconVisualizar} alt="Editar" width="18" />
                                                </button>
                                                {assinatura.link && (
                                                    <button 
                                                        className="btn btnIcon" 
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(assinatura.link);
                                                            const btn = document.createElement('div');
                                                            btn.innerHTML = 'Link copiado!';
                                                            btn.style.position = 'fixed';
                                                            btn.style.top = '20px';
                                                            btn.style.right = '20px';
                                                            btn.style.background = '#28a745';
                                                            btn.style.color = 'white';
                                                            btn.style.padding = '10px';
                                                            btn.style.borderRadius = '4px';
                                                            btn.style.zIndex = '9999';
                                                            document.body.appendChild(btn);
                                                            setTimeout(() => document.body.removeChild(btn), 2000);
                                                        }}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <img src={iconCopy} alt="Copiar link" width="18" />
                                                    </button>
                                                )}
                                                <button className="btn btnIcon" onClick={() => handleDelete(assinatura.id)}>
                                                    <img src={iconLixeira} alt="Excluir" width="18" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <NoResults />
                                )}
                            </tbody>
                        </table>

                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                loadAssinaturas(page);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
