import React, { useState, useEffect } from 'react';
import api from '../../../Utils/services';
import Header from '../../../Components/Header';
import SideMenu from '../../../Components/SideMenu';
import NoResults from '../../../Components/NoResults';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import Funcoes from '../../../Utils/functions';

export default function Taxas() {
    const [taxas, setTaxas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCalculadora, setShowCalculadora] = useState(false);
    const [bandeiraSelecionada, setBandeiraSelecionada] = useState('');
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState(1);
    const [tipoTaxa, setTipoTaxa] = useState('cliente'); // 'cliente' ou 'vendedor'
    const [valor, setValor] = useState(0);
    const [valorFinal, setValorFinal] = useState(0);

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            const fetchData = async () => {
                try {
                    const response = await api.get('card-fees', user.token);
                    if (response.data && response.data.data) {
                        setTaxas(response.data.data);
                        if (response.data.data.length > 0) {
                            setBandeiraSelecionada(response.data.data[0].brand);
                        }
                    }
                } catch (error) {
                    api.handleErros(error.response);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, []);

    const tiposTaxas = [
        { key: 'credit.1x', label: 'Crédito 1x' },
        { key: 'credit.2x', label: '2x' },
        { key: 'credit.3x', label: '3x' },
        { key: 'credit.4x', label: '4x' },
        { key: 'credit.5x', label: '5x' },
        { key: 'credit.6x', label: '6x' },
        { key: 'credit.7x', label: '7x' },
        { key: 'credit.8x', label: '8x' },
        { key: 'credit.9x', label: '9x' },
        { key: 'credit.10x', label: '10x' },
        { key: 'credit.11x', label: '11x' },
        { key: 'credit.12x', label: '12x' }
    ];

    const getTaxaValue = (taxa, tipoTaxa) => {
        const [type, installment] = tipoTaxa.split('.');
        return taxa.fees[type][installment];
    };

    const calcularValor = () => {
        const taxa = taxas.find(t => t.brand === bandeiraSelecionada);
        if (!taxa) return;

        const taxaValor = getTaxaValue(taxa, `credit.${parcelasSelecionadas}x`);
        
        if (tipoTaxa === 'cliente') {
            // Calcula quanto o cliente vai pagar
            const valorComTaxa = valor * (1 + (taxaValor / 100));
            setValorFinal(valorComTaxa);
        } else {
            // Calcula quanto o vendedor vai receber
            const valorSemTaxa = valor / (1 - (taxaValor / 100));
            setValorFinal(valorSemTaxa);
        }
    };

    useEffect(() => {
        if (valor > 0) {
            calcularValor();
        }
    }, [valor, bandeiraSelecionada, parcelasSelecionadas, tipoTaxa]);

    const handleOpenCalculadora = () => {
        setValor(0);
        setValorFinal(0);
        setParcelasSelecionadas(1);
        setTipoTaxa('cliente');
        setShowCalculadora(true);
    };

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <>
            <Modal show={showCalculadora} largura={390}>
                <div className="modalConfirm">
                    <h3>Calculadora de Taxas</h3>
                    <div style={{ textAlign: 'left' }}>
                        <Input
                            type="select"
                            label="Bandeira"
                            value={bandeiraSelecionada}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setBandeiraSelecionada(e.target.value)}
                            optionsData={taxas.map(taxa => ({
                                value: taxa.brand,
                                text: taxa.brand
                            }))}
                        />

                        <Input
                            type="select"
                            label="Parcelas"
                            value={parcelasSelecionadas}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setParcelasSelecionadas(parseInt(e.target.value))}
                            optionsData={Array.from({ length: 12 }, (_, i) => ({
                                value: i + 1,
                                text: `${i + 1}x`
                            }))}
                        />

                        <Input
                            type="select"
                            label="Taxa"
                            value={tipoTaxa}
                            divStyle={{ marginBottom: 27 }}
                            onChange={e => setTipoTaxa(e.target.value)}
                            optionsData={[
                                { value: 'cliente', text: 'Do Cliente' },
                                { value: 'vendedor', text: 'Do Vendedor' }
                            ]}
                        />

                        <Input
                            type="money"
                            label={tipoTaxa === 'cliente' ? 'Quanto você quer receber?' : 'Quanto o cliente pagará?'}
                            value={valor}
                            divStyle={{ marginBottom: 27 }}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                        />

                        {valor > 0 && (
                            <div style={{ 
                                marginTop: 20, 
                                padding: 15, 
                                background: '#f8f9fa', 
                                borderRadius: 4,
                                textAlign: 'center'
                            }}>
                                <p style={{ 
                                    fontSize: 14, 
                                    color: '#666', 
                                    marginBottom: 5 
                                }}>
                                    {tipoTaxa === 'cliente' ? 'Cliente pagará' : 'Você receberá'}
                                </p>
                                <h2 style={{ 
                                    color: '#28a745', 
                                    margin: 0 
                                }}>
                                    R$ {Funcoes.float2Preco(valorFinal)}
                                </h2>
                            </div>
                        )}
                    </div>

                    <button className="btnCancelar" onClick={() => setShowCalculadora(false)}>
                        Fechar
                    </button>
                </div>
            </Modal>

            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Taxas</h1>
                            </div>
                            <button className="btn" onClick={handleOpenCalculadora}>
                                Calculadora de Taxas
                            </button>
                        </div>
                        <div className="panel">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            {taxas.map(taxa => (
                                                <th key={taxa.brand}>{taxa.brand}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxas.length > 0 ? (
                                            tiposTaxas.map(tipoTaxa => (
                                                <tr key={tipoTaxa.key}>
                                                    <td>{tipoTaxa.label}</td>
                                                    {taxas.map(taxa => (
                                                        <td key={`${taxa.brand}-${tipoTaxa.key}`}>
                                                            {getTaxaValue(taxa, tipoTaxa.key)}%
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={taxas.length + 1}>
                                                    <NoResults />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
