import React from 'react';

export default function NoResults() {
    return (
        <tr className="no-results">
            <td colSpan="100%" style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                Nenhum resultado encontrado
            </td>
        </tr>
    );
}
