import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import iconSair from '../resources/img/icon-sair.svg';
import ApiService from '../Utils/services';

class Header extends Component {
    handleLogout = async (e) => {
        e.preventDefault();
        try {
            const userData = sessionStorage.getItem('userData');
            if (userData) {
                const user = JSON.parse(userData);
                await ApiService.postEmpresa('logout', {}, user.token);
                sessionStorage.removeItem('userData');
            }
            this.props.history.push('/');
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
            // Em caso de erro, ainda assim limpa os dados e redireciona
            sessionStorage.removeItem('userData');
            this.props.history.push('/');
        }
    };

    render() {
        return (
            <Fragment>
                <div className="navbar">
                    <div className="barLinks">
                        <ul>
                            <li>
                                <a href="#" onClick={this.handleLogout}>
                                    Sair <img src={iconSair} alt="Ícone de logout" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Header);