import React, { useState, useEffect } from 'react';
import api from '../../../Utils/services';
import Header from '../../../Components/Header';
import SideMenu from '../../../Components/SideMenu';

export default function MinhaConta() {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = JSON.parse(sessionStorage.getItem('userData'))?.token;
                const response = await api.get('me', token);
                if (response.data && response.data.data) {
                    setUserData(response.data.data);
                }
            } catch (error) {
                api.handleErros(error.response);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div>Carregando...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (!userData) {
        return (
            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div>Erro ao carregar dados. Por favor, tente novamente.</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div className="headerBtn">
                            <h1>Minha Conta</h1>
                        </div>
                        <div className="panel">
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <h5>Dados da Empresa</h5>
                                    <p><strong>Razão Social:</strong> {userData.name || '-'}</p>
                                    <p><strong>Nome Fantasia:</strong> {userData.fantasy_name || '-'}</p>
                                    <p><strong>CNPJ:</strong> {userData.document || '-'}</p>
                                    <p><strong>Email:</strong> {userData.email || '-'}</p>
                                    <p><strong>Telefone:</strong> {userData.phone || '-'}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5>Endereço</h5>
                                    <p><strong>Rua:</strong> {userData.address?.street || '-'}</p>
                                    <p><strong>Número:</strong> {userData.address?.number || '-'}</p>
                                    <p><strong>Bairro:</strong> {userData.address?.district || '-'}</p>
                                    <p><strong>Complemento:</strong> {userData.address?.complement || '-'}</p>
                                    <p><strong>CEP:</strong> {userData.address?.postal_code || '-'}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Informações Adicionais</h5>
                                    <p><strong>Nome para Contato:</strong> {userData.contact_name || '-'}</p>
                                    <p><strong>Data de Cadastro:</strong> {userData.created_at ? new Date(userData.created_at).toLocaleDateString() : '-'}</p>
                                    <p><strong>Status:</strong> {userData.active ? 'Ativo' : 'Inativo'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
